import { Modal, PrimaryButton, toast } from '@systemeio/ui-shared'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { CreateDataType } from 'shared/components/create-modal'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import useWindowSize from 'shared/hooks/use-window-size'
import { BadRequest } from '../errors/bad-request'
import { InternalError } from '../errors/internal-error'
import { FieldErrorAndDescription } from './form/field-error-and-description'


export type EditDataType<T> = { [Property in keyof T]: T[Property] }

type PropUpdate<T> = {
  [K in keyof T]: {
    field: K
    onRender: (
      value: T[K],
      onChange: (value: T[K]) => void,
      state: T,
      instance: T,
      isPreFetching: boolean,
      onChangeState: (state: T) => void,
    ) => JSX.Element
    shouldRender?: (state: CreateDataType<T>, instance: T) => boolean
    disabled?: (value: T[K]) => boolean
  }
}[keyof T]

export type EditableItemType<T> = PropUpdate<T> | PropUpdate<T>[]

type TempStateType<T> = T

export interface EditProps<T, K> {
  opened: boolean
  onClose: () => void
  onOpen?: (instance: T) => void
  isPreFetching?: boolean
  afterLeave?: () => void
  instance: T
  additionalData?: K
  caption?: string
  fullCaption?: string
  editable: EditableItemType<T>[]
  onEdit: (data: EditDataType<T>) => Promise<void> | void
  toastCaption?: string | JSX.Element
  additionalInfo?: string | JSX.Element
}

function EditModal<T, K>({
  opened,
  onOpen,
  isPreFetching = false,
  onClose,
  afterLeave,
  editable,
  caption,
  fullCaption,
  onEdit,
  instance,
  additionalData,
  toastCaption,
  additionalInfo,
}: EditProps<T, K>) {
  const { t } = useLocoTranslation()
  const [error, setError] = useState('')
  const { width } = useWindowSize()

  const editableRef = useRef(editable)

  const setDefaultValues = useCallback((): TempStateType<T> => {
    const obj: TempStateType<T> = {} as TempStateType<T>
    editableRef.current.forEach(value => {
      if (Array.isArray(value)) {
        value.forEach(el => {
          obj[el.field] = instance[el.field]
        })
      } else {
        obj[value.field] = instance[value.field]
      }
    })
    if (additionalData) {
      Object.keys(additionalData).map(el => {
        const key = el as keyof {}
        obj[key] = additionalData[key]
      })
    }
    return obj
  }, [additionalData, instance])

  useEffect(() => {
    if (onOpen && opened) {
      onOpen(instance)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance, opened])

  const [tempState, setTempState] = useState<TempStateType<T>>(setDefaultValues())

  const disabledSave = editable.some(setting => {
    if (Array.isArray(setting)) {
      return setting.some(item => (item.disabled ? item.disabled(tempState[item.field]) : false))
    } else {
      return setting.disabled ? setting.disabled(tempState[setting.field]) : false
    }
  })

  useEffect(() => {
    setTempState(setDefaultValues())
  }, [setDefaultValues])

  useEffect(() => {
    if (additionalData) {
      setTempState(prev => ({ ...prev, ...additionalData }))
    }
  }, [additionalData])

  const clearModal = () => {
    setTempState(setDefaultValues())
    setError('')
  }

  const [isFetching, setIsFetching] = useState(false)

  const handleConfirm = async () => {
    try {
      setError('')
      setIsFetching(true)
      await onEdit(tempState as EditDataType<T>)
      setIsFetching(false)
      onClose()
      if (toastCaption) {
        toast.success(toastCaption)
      }
    } catch (e) {
      setIsFetching(false)
      if (e instanceof BadRequest) {
        if (e.errors.common) {
          setError(e.errors.common.join(''))
        }
      } else if (e instanceof InternalError) {
        setError(t('core.error.internal_error_message'))
      } else {
        toast.error(t('global.error'))
      }
    }
  }
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={
        fullCaption ? fullCaption : t('dashboard.actions.edit', { module: caption?.toLowerCase() })
      }
      isFetching={isFetching}
      afterLeave={() => {
        afterLeave && afterLeave()
        clearModal()
      }}
    >
      <form className="flex flex-col gap-6 lg:gap-10">
        <div className="flex flex-col gap-5">
          {editable.map((setting, idx) => {
            if (Array.isArray(setting)) {
              return width < 1024 ? (
                setting.map(
                  el =>
                    (el.shouldRender
                      ? el.shouldRender(tempState as EditDataType<T>, instance)
                      : true) &&
                    el.onRender(
                      tempState[el.field],
                      value => setTempState(prev => ({ ...prev, [el.field]: value })),
                      tempState,
                      instance,
                      isPreFetching,
                      newState => setTempState(newState),
                    ),
                )
              ) : (
                <div
                  key={`${idx}-wrapper`}
                  className="flex justify-between items-start [&>*]:flex-1 gap-10"
                >
                  {setting.map(
                    el =>
                      (el.shouldRender
                        ? el.shouldRender(tempState as EditDataType<T>, instance)
                        : true) &&
                      el.onRender(
                        tempState[el.field],
                        value => setTempState(prev => ({ ...prev, [el.field]: value })),
                        tempState,
                        instance,
                        isPreFetching,
                        newState => setTempState(newState),
                      ),
                  )}
                </div>
              )
            } else {
              return (
                (setting.shouldRender
                  ? setting.shouldRender(tempState as EditDataType<T>, instance)
                  : true) &&
                setting.onRender(
                  tempState[setting.field],
                  value => setTempState(prev => ({ ...prev, [setting.field]: value })),
                  tempState,
                  instance,
                  isPreFetching,
                  newState => setTempState(newState),
                )
              )
            }
          })}
        </div>
        <div className="flex justify-center">
          <PrimaryButton
            disabled={isPreFetching || disabledSave}
            width="large"
            onClick={async (e: React.MouseEvent<HTMLButtonElement>) => {
              e.preventDefault()
              await handleConfirm()
            }}
            isFetching={isFetching}
            type="submit"
          >
            {t('global.save')}
          </PrimaryButton>
        </div>
        <FieldErrorAndDescription
          error={error}
          errorClassName={'text-center mt-2'}
          description={additionalInfo}
          descriptionClassName={'flex w-full justify-center'}
        />
      </form>
    </Modal>
  )
}

export default EditModal
